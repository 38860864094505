define('frontend/helpers/slugify-title', ['exports', 'pro-slugs'], function (exports, _proSlugs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.slugifyTitle = slugifyTitle;
  function slugifyTitle(params) {
    var namedArgs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return (0, _proSlugs.default)(namedArgs.title) + '-' + namedArgs.id;
  }

  exports.default = Ember.Helper.helper(slugifyTitle);
});