define('frontend/serializers/article', ['exports', 'ember-data', 'pro-slugs', 'frontend/config/environment'], function (exports, _emberData, _proSlugs, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

			var convertToBoolean = function convertToBoolean(string) {
				return string.toLowerCase() === 'yes' ? true : false;
			};

			if (requestType === 'findRecord') {
				payload = {
					article: payload.map(function (article) {
						return {
							id: article.articleID,
							type: article.articleType,
							articleID: article.articleID,
							image: article.articleImage,
							title: article.articleTitle,
							body: article.articleDescription,
							keywords: article.articleKeywords,
							allowComment: convertToBoolean(article.articleAllowComment),
							allowGallery: convertToBoolean(article.articleAllowGallery),
							allowSubmission: convertToBoolean(article.articleAllowSubmission),
							allowVoting: convertToBoolean(article.articleAllowUpvoting),
							dateEndVoting: article.articleEndVotingDate,
							dateExpire: article.articleExpireDate,
							dateCreated: article.createdAt,
							dateUpdated: article.updatedAt,
							rules: article.articleRules,
							dateStart: article.articleStartDate,
							status: article.articleStatus,
							submissionType: article.articleSubmissionType,
							userID: article.userID,
							tag: article.articleTags,
							url: _environment.default.domainPath + 'article/' + (0, _proSlugs.default)(article.articleTitle) + '-' + article.articleID,
							links: {
								submissions: '/articles/' + article.articleID + '/submissions/'
							}
						};
					})[0]
				};
			} else if (requestType === 'deleteRecord') {
				console.log('@@@@ Serializer handle delete record');
			} else {
				// console.log('@@@@ Noramalizing ',requestType,' payload: ',payload);
				payload = {
					articles: (payload.result || []).map(function (article) {
						return {
							id: article.articleID,
							type: article.articleType,
							body: article.articleDescription,
							keywords: article.articleKeywords,
							articleID: article.articleID,
							image: article.articleImage,
							dateStart: article.articleStartDate,
							tag: article.articleTags,
							title: article.articleTitle,
							firstName: article.userFirstName,
							lastName: article.userLastName,
							url: _environment.default.domainPath + 'article/' + (0, _proSlugs.default)(article.articleTitle) + '-' + article.articleID,
							links: {
								submissions: '/articles/' + article.articleID + '/submissions/'
							}
						};
					})
				};
			}
			// console.log(`Payload for requestType: ${requestType}`, payload)
			return this._super(store, primaryModelClass, payload, id, requestType);
		}
	});
});