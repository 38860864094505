define('frontend/controllers/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      submit: function submit() {
        var _this = this;

        console.log('@@@@ Submit user changes to server');
        window.ga('send', 'event', 'Account', 'Click', 'Profile Update');
        this.get('model').save().then(function () {
          _this.get('flashMessages').success('Profile is successfully updated');
          console.log('@@@@ Profile saved');
        }).catch(function (err) {
          _this.get('flashMessages').danger('Profile is not updated');
          console.error('#### Error in saving profile', err);
        });
      }
    }
  });
});