define('frontend/controllers/index', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		serverURL: _environment.default.serverPath,
		actions: {
			sendGAEvent: function sendGAEvent(eventCategory, eventLabel) {
				window.ga('send', 'event', eventCategory, 'Click', eventLabel);
			}
		}
	});
});