define('frontend/serializers/asset', ['exports', 'ember-data', 'frontend/config/environment'], function (exports, _emberData, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			// console.log('@@@@ Normalize response: ',payload);
			if (payload.assets) {
				payload = {
					assets: payload.assets.map(function (asset) {
						return {
							id: asset.articleSubmissionAssetID,
							caption: asset.caption,
							type: asset.assetType,
							image: _environment.default.serverPath + 'storage/submission/photo/' + asset.assetPath,
							assetID: asset.articleSubmissionAssetID
						};
					})
				};
			} else if (payload.asset) {
				payload = {
					asset: {
						id: payload.asset.articleSubmissionAssetID,
						caption: payload.asset.caption,
						type: payload.asset.assetType,
						image: _environment.default.serverPath + 'storage/submission/photo/' + payload.asset.assetPath,
						assetID: payload.asset.articleSubmissionAssetID
					}
				};
			} else {
				return { meta: {} };
			}
			// console.log('serializer...',{store, primaryModelClass, payload, id, requestType})
			return this._super(store, primaryModelClass, payload, id, requestType);
		}
	});
});