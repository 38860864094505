define('frontend/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      // console.log('@@@@ Payload on find user: ',payload,' id: ',id);
      if (payload.success) {
        var user = payload.data;
        // console.log('@@@@ using as payload: ',user);
        return this._super(store, primaryModelClass, { user: {
            firstName: user.userFirstName,
            lastName: user.userLastName,
            email: user.userEmail,
            id: id,
            userID: id,
            url: user.url,
            avatar: user.avatar,
            bio: user.bio,
            location: user.location
          } }, id, requestType);
      } else {
        throw new _emberData.default.AdapterError();
      }
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      var user = payload.user;
      // console.log('@@@@ using as payload: ',user);
      return this._super(store, primaryModelClass, { user: {
          firstName: user.userFirstName,
          lastName: user.userLastName,
          email: user.userEmail,
          id: id,
          userID: id,
          url: user.url,
          avatar: user.avatar,
          bio: user.bio,
          location: user.location
        } }, id, requestType);
    },
    serialize: function serialize(snapshot) {
      var json = {
        id: snapshot.id
      };
      snapshot.eachAttribute(function (key) {
        var ser = {
          firstName: 'userFirstName',
          lastName: 'userLastName',
          email: 'userEmail'
        }[key] || key;
        json[ser] = snapshot.attr(key);
      });
      snapshot.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          json[key] = snapshot.belongsTo(key, { id: true });
        } else if (relationship.kind === 'hasMany') {
          json[key] = snapshot.hasMany(key, { ids: true });
        }
      });

      return json;
    }
  });
});