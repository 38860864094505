define('frontend/controllers/gallery', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service('session'),
		serverURL: _environment.default.serverPath,
		rootUrl: _environment.default.rootURL,
		queryParams: ['page'],
		page: 1,
		userSubmission: Ember.computed('session.data.authenticated', function () {
			// Locate any existing submission for the logged in user
			var auth = this.get('session.data.authenticated');
			var subs = this.get('model.article.submissions');
			if (auth && subs && subs.length > 0) {
				return subs.find(function (s) {
					return s.userID == auth.userID;
				});
			}
			return null;
		}),
		actions: {
			showGallerySubmission: function showGallerySubmission() {
				Ember.$('#button-show-upload').hide();
				Ember.$('.challenge-submit').show();
			},
			nextPage: function nextPage() {
				var page = this.get('page');
				this.set('page', page + 1);
			},
			prevPage: function prevPage() {
				var page = this.get('page');
				this.set('page', page - 1);
			}
		}
	});
});