define('frontend/helpers/category-tag-class', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.categoryTag = categoryTag;
	function categoryTag(contentType) {
		var classes = ['article', 'challenge', 'create'];
		var index = contentType ? contentType[0] : 1;
		return classes[index - 1];
	}

	exports.default = Ember.Helper.helper(categoryTag);
});