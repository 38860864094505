define('frontend/components/search-input', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['search-block'],
    showViewAll: false,
    showSuggestions: false,
    autoCompleteArticles: Ember.A(),
    keyPress: function keyPress(event) {
      if (event.keyCode === 13) {
        this.set('autoCompleteArticles', Ember.A());
        this.set('showViewAll', false);
        this.set('showSuggestions', false);
        this.get('submitSearch')();
      }
    },
    keyUp: function keyUp() {
      var _this = this;

      var query = this.get('searchKeywords');

      if (query && query.trim().length > 3) {
        Ember.$.ajax({
          method: "GET",
          url: _environment.default.serverPath + "search?q=" + query
        }).then(function (data) {
          if (data.result && data.result.length) {
            _this.set('showSuggestions', true);
            var autoSuggestions = data.result.map(function (_ref) {
              var articleTitle = _ref.articleTitle,
                  articleID = _ref.articleID;
              return {
                articleTitle: articleTitle, articleID: articleID
              };
            }).slice(0, 5);
            if (data.result.length > 5) {
              _this.set('showViewAll', true);
            } else {
              _this.set('showViewAll', false);
            }
            if (_this.get('searchKeywords')) {
              _this.set('autoCompleteArticles', autoSuggestions);
            }
          }
        });
      } else {
        this.set('autoCompleteArticles', Ember.A());
        this.set('showViewAll', false);
      }
    },

    actions: {
      hideSuggestions: function hideSuggestions() {
        this.set('showSuggestions', false);
      },
      submitSearch: function submitSearch() {
        this.set('autoCompleteArticles', Ember.A());
        this.set('showViewAll', false);
        this.set('showSuggestions', false);
        this.get('submitSearch')();
      }
    }
  });
});