define('frontend/adapters/application', ['exports', 'ember-data', 'frontend/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
		host: _environment.default.serverPath.replace(/\/$/, ''),
		authorizer: 'authorizer:oauth2'
	});
});