define("frontend/controllers/activate", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["token"],
    token: null,
    filterUsers: function () {
      var _this = this;

      var token = this.get("token");
      if (token != null) {
        Ember.$.ajax({
          method: "GET",
          url: _environment.default.serverPath + "users/activate/",
          data: {
            token: token
          }
        }).then(function (data) {
          if (data["errors"]) {
            _this.get("flashMessages").danger(data["errors"]);
            _this.transitionToRoute("login");
          } else {
            _this.get("flashMessages").success("You account has been activated.");
            _this.transitionToRoute("login");
          }
        });
      }
    }.observes("token").on("init")
  });
});