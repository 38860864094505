define('frontend/adapters/asset', ['exports', 'frontend/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _application.default.extend({
		buildURL: function buildURL(modelName, id, snapshot, requestType, query) {

			// console.log('asset apdapter',{modelName, id, snapshot, requestType, query})

			if (requestType === 'deleteRecord') {

				var articleID = snapshot.adapterOptions.articleID;
				var submissionID = snapshot.adapterOptions.submissionID;
				var assetID = snapshot.adapterOptions.assetID;
				var url = this.host + '/articles/' + articleID + '/submissions/' + submissionID + '/asset/' + assetID;
				// console.log('deleting...', url);
				return url;
			} else if (requestType === 'createRecord') {
				var _articleID = snapshot.adapterOptions.articleID;
				var _submissionID = snapshot.adapterOptions.submissionID;
				var _url = this.host + '/articles/' + _articleID + '/submissions/' + _submissionID + '/asset/new';
				// console.log('Create record...', url);
				return _url;
			} else {
				// console.log('@@@@ Unknown request type: ',requestType);
				return new Error('Check asset adapter to support a new requestType.', 'adapter/submission.js', modelName, id, snapshot, requestType, query);
			}
		},
		handleResponse: function handleResponse(status, headers, payload, requestData) {
			// console.log('@@@@ In handle response. Asset  Status: ',status,' headers: ',headers,' payload: ',payload);
			if (payload && payload.success) {
				if (payload.result) {
					return payload.result;
				} else {
					return this._super(status, headers, payload, requestData);
				}
			} else if (payload && !payload.success && payload.errors) {
				console.log('@@@@ Converting errors to JSON-API ', payload.errors, ' ', _typeof(payload.errors));
				if (typeof payload.errors === 'string') {
					return this._super(status, headers, { errors: [{ title: payload.errors }] }, requestData);
				} else {
					return this._super(status, headers, payload, requestData);
				}
			} else {
				return this._super(status, headers, payload, requestData);
			}
		}
	});
});