define("frontend/router", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    initialLoad: true
  });

  Router.map(function () {
    this.route("login");
    this.route("register");
    this.route("change-password");
    this.route("activate");
    this.route("forget-password");
    this.route("reset-password-process");
    this.route("articles");
    this.route("create");
    this.route("challenges");
    this.route("article", { path: "/article/:slug" }, function () {
      this.route("gallery", { path: "/gallery" });
    });
    this.route("gallery", { path: "/gallery/:articleID" }, function () {});

    this.route("profile");
    this.route("privacy");
    this.route("search");
    this.route('404', { path: '/*path' });
  });

  Router.reopen({
    notifyGoogleAnalytics: function () {
      var initialLoad = this.get("initialLoad");
      if (initialLoad) {
        this.set('initialLoad', false);
      } else {
        return window.ga("send", "pageview", {
          page: this.get("url"),
          title: this.get("url")
        });
      }
    }.on("didTransition")
  });

  exports.default = Router;
});