define('frontend/utils/slug', ['exports', 'pro-slugs/utils/slug'], function (exports, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _slug.default;
    }
  });
});