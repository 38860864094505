define('frontend/components/article-post', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		serverURL: _environment.default.serverPath,
		classNames: ['post'],
		actions: {
			sendGAEvent: function sendGAEvent(isRecommendation) {
				if (isRecommendation) {
					window.ga('send', 'event', 'Recommendations', 'Click');
				}
			}
		},
		init: function init() {
			this._super.apply(this, arguments);
		}
	});
});