define('frontend/controllers/search', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		serverURL: _environment.default.serverPath,
		rootUrl: _environment.default.rootURL,
		queryParams: ['q'],
		// queryParams: [
		// 	'page',
		// ],
		// page: 1,
		perPage: 4,
		actions: {
			// nextPage() {
			//
			// 	let page = this.get('page');
			//
			// 	this.set('page', page + 1);
			// },
			// prevPage() {
			// 	let page = this.get('page');
			// 	this.set('page', page - 1);
			// }
			loadMore: function loadMore() {
				var _this = this;

				// Load more challenges beyond those currently loaded
				var q = this.get('q');
				var count = this.get('model.articles').length;
				var page = Math.floor((count - 1) / this.get('perPage')) + 1;
				var url = _environment.default.serverPath + 'search?per_page=' + this.get('perPage') + '&page=' + (page + 1);
				if (q) {
					url += '&q=' + q;
				}
				if (this.get('model.pagination')[0].total_pages > page) {
					return Ember.$.ajax({
						method: 'GET',
						url: url
					}).then(function (result) {
						_this.set('model.pagination', result.pagination);
						_this.get('model.articles').pushObjects(result.result);
					}, function (error) {
						console.log(error.errors);
						if (error.errors) {
							_this.get("flashMessages").warning(error["errors"]);
						}
					});
				}
			}
		}
	});
});