define('frontend/helpers/category-href', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.categoryHref = categoryHref;
	function categoryHref(contentType) {
		var categoryUrls = ['/articles', '/challenges', '/create'];
		var index = contentType ? contentType[0] : 1;
		return categoryUrls[index - 1];
	}

	exports.default = Ember.Helper.helper(categoryHref);
});