define('frontend/routes/create', ['exports', 'frontend/config/environment', 'ember-cli-reset-scroll'], function (exports, _environment, _emberCliResetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_emberCliResetScroll.default, {
		title: '8th Mind: Create',
		perPage: 4,
		queryParams: {
			page: {
				refreshModel: true
			}
		},
		resetScroll: 0,
		beforeModel: function beforeModel(transition) {
			var loginController = this.controllerFor('login');
			loginController.set('previousTransition', transition);
		},
		resetController: function resetController(controller, isExiting) {
			if (isExiting) {
				// isExiting would be false if only the route's model was changing
				controller.set('tag', undefined);
				this.set('tag', undefined);
			}
		},

		model: function model(params) {

			var url = params['page'] ? _environment.default.serverPath + 'articles/?content_type=3&per_page=' + this.get('perPage') + '&page=' + params['page'] : _environment.default.serverPath + 'articles/?content_type=3&per_page=' + this.get('perPage');

			if (params.tag) {
				url += '&tag=' + params['tag'];
				this.set('tag', params.tag);
			}

			return Ember.$.ajax({
				method: "GET",
				url: url
			}).then(function (result) {
				return Ember.RSVP.hash({
					articles: result.result,
					pagination: result.pagination
				});
			});
		},
		setupController: function setupController(controller, model) {
			// Call _super for default behavior
			this._super(controller, model);
			controller.set('tag', this.get('tag'));
		}
	});
});