define('frontend/controllers/article', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service('session'),
		serverURL: _environment.default.serverPath,
		rootUrl: _environment.default.rootURL,
		articleTags: Ember.computed('model.article.tag', function () {
			var stringTags = this.get('model.article.tag');
			if (!stringTags) {
				return Ember.A();
			}
			var tags = stringTags.split(',').map(function (t) {
				return t.trim();
			}).filter(function (t) {
				return !!t;
			});
			return Ember.A(tags);
		})
		// userSubmission: Ember.computed('session.data.authenticated.userID',
		//   'model.article.submissions.@each.isDeleted', function() {
		// 	// Locate any existing submission for the logged in user
		// 	let auth = this.get('session.data.authenticated.userID');
		// 	let subs = this.get('model.article.submissions');
		//   // console.log('@@@@ Auth: ',auth,' subs: ',subs.map(s => s.get('userID')));
		// 	if (auth && subs) {
		//     // console.log('@@@@ Sub user ids: ',subs.map(s => s.get('userID')));
		// 		return subs.find(s => s.get('userID') === auth && !s.get('isDeleted'));
		// 	}
		// 	return null;
		// })
	});
});