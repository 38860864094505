define('frontend/routes/search', ['exports', 'frontend/config/environment', 'ember-cli-reset-scroll'], function (exports, _environment, _emberCliResetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_emberCliResetScroll.default, {
		title: '8th Mind: Search',
		queryParams: {
			q: {
				refreshModel: true
			}
		},
		perPage: 4,
		// queryParams: {
		// 	page: {
		// 		refreshModel: true
		// 	}
		// },
		resetScroll: 0,
		beforeModel: function beforeModel(transition) {
			var loginController = this.controllerFor('login');
			loginController.set('previousTransition', transition);
		},
		actions: {
			didTransition: function didTransition() {
				// console.log('didTransition', true);
				return true; // Bubble the didTransition event
			}
		},
		model: function model(params) {
			var url = params['page'] ? _environment.default.serverPath + 'search?per_page=' + this.get('perPage') + '&page=' + params['page'] : _environment.default.serverPath + 'search?per_page=' + this.get('perPage');

			if (!params.q || params.q.length < 3) {
				return this.get("flashMessages").warning('Search terms must be at least 3 characters in length');
			}

			url += '&q=' + params['q'];
			this.set('q', params.q);

			return Ember.$.ajax({
				method: 'GET',
				url: url
			}).then(function (result) {

				var articles = result.result;
				return Ember.RSVP.hash({
					articles: articles,
					pagination: result.pagination
				});
			});
		},
		setupController: function setupController(controller, model) {
			// Call _super for default behavior
			this._super(controller, model);
			controller.set('q', this.get('q'));
		}
	});
});