define('frontend/helpers/ember-particles-options', ['exports', 'ember-particles/helpers/ember-particles-options'], function (exports, _emberParticlesOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberParticlesOptions.default;
    }
  });
  Object.defineProperty(exports, 'emberParticlesOptions', {
    enumerable: true,
    get: function () {
      return _emberParticlesOptions.emberParticlesOptions;
    }
  });
});