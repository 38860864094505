define('frontend/components/share-panel', ['exports', 'ember-social-share/components/share-panel'], function (exports, _sharePanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sharePanel.default;
    }
  });
});