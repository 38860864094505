define('frontend/components/article-challenges', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		session: Ember.inject.service('session'),
		serverURL: _environment.default.serverPath,
		classNames: ['article-challenge'],
		actions: {
			sendGAEvent: function sendGAEvent(eventLabel) {
				window.ga('send', 'event', 'Submissions', 'Click', eventLabel);
			}
		},
		init: function init() {
			this._super.apply(this, arguments);
		}
	});
});