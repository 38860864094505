define('frontend/components/upload-field', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    session: Ember.inject.service('session'),
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      if (!Ember.isEmpty(files)) {
        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;

          if (files[0].size > 10 * 1024 * 1024) {
            return _this.get('error')({
              errors: ['File size (' + files[0].size + ') too large (10MB limit)']
            });
          }

          if (!files[0].type.includes('image')) {
            return _this.get('error')({
              errors: ['Only images are supproted (JPEG, JPG, GIF, PNG ect.)']
            });
          }
          // peform actions prior to doing the upload
          // console.log('@@@@ Performing upload on file select with url: ',this.get('url'));
          var uploader = function uploader(url) {
            return _emberUploader.default.Uploader.create({
              url: url,
              paramName: _this.get('fileParamName'),
              ajaxSettings: { headers: headers }
            });
          };

          // console.log('@@@@ Uploading to ',this.get('url'));
          // console.log('@@@@ Extra data: ',this.get('extraData'));
          _this.get('setUploader')(uploader, files);
        });
      }
    }
  });
});