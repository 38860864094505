define('frontend/helpers/category-tag', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.categoryTag = categoryTag;
	function categoryTag(contentType) {
		// Labels for Articles, Challenges and Creates
		var labels = ['A', 'C', 'C'];
		var index = contentType ? contentType[0] : 1;
		return labels[index - 1];
	}

	exports.default = Ember.Helper.helper(categoryTag);
});