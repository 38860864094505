define('frontend/controllers/article/gallery', ['exports', 'frontend/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service('session'),
		serverURL: _environment.default.serverPath,
		rootUrl: _environment.default.rootURL,
		queryParams: ['page'],
		perPage: 10,
		page: 1,
		// userSubmission: Ember.computed('session.data.authenticated.userID',
		//   'model.article.submissions.@each.isDeleted', function() {
		// 	// Locate any existing submission for the logged in user
		// 	let auth = this.get('session.data.authenticated.userID');
		// 	let subs = this.get('model.article.submissions');
		//   // console.log('@@@@ Auth: ',auth,' subs: ',subs.map(s => s.get('userID')));
		// 	if (auth && subs) {
		//     // console.log('@@@@ Sub user ids: ',subs.map(s => s.get('userID')));
		// 		return subs.find(s => s.get('userID') === auth && !s.get('isDeleted'));
		// 	}
		// 	return null;
		// }),
		actions: {
			loadMore: function loadMore() {
				var _this = this;

				// Load more articles beyond those currently loaded
				var count = this.get('model.article.submissions').get('length');
				var page = Math.floor((count - 1) / this.get('perPage')) + 1;
				var articleID = this.get('model.article.id');
				var url = _environment.default.serverPath + 'articles/' + articleID + '/submissions/?per_page=' + this.get('perPage') + '&page=' + (page + 1);
				var pagination = this.get('model.pagination')[0];
				var store = this.get('store');
				var model = this.get('model');

				if (pagination.total_pages > page) {
					return Ember.$.get(url).then(function (_ref) {
						var result = _ref.result,
						    pagination = _ref.pagination;

						_this.set('model.pagination', pagination);
						result.map(function (s) {
							return Ember.$.get(_environment.default.serverPath + 'articles/' + s.articleID + '/submissions/' + s.articleSubmissionID).then(function (data) {
								var rec = store.createRecord('submission', {
									id: data.articleSubmissionID,
									title: data.title,
									name: data.userDisplayName,
									votes: data.totalUpvotes,
									upvote: data.upvote,
									userID: data.userID,
									thumb: data.thumbUrl,
									dateCreated: data.createdAt,
									articleID: data.articleID,
									userDisplayName: data.userDisplayName,
									assets: (data.assets || []).map(function (asset) {
										return store.createRecord('asset', {
											id: asset.articleSubmissionAssetID,
											caption: asset.caption,
											type: asset.assetType,
											image: _environment.default.serverPath + 'storage/submission/photo/' + asset.assetPath,
											assetID: asset.articleSubmissionAssetID
										});
									}),
									links: {
										assets: '/articles/' + s.articleID + '/submissions/' + s.articleSubmissionID
									}
								});
								model.article.get('submissions').pushObject(rec);
							});
						});
					});
				}
			},
			showGallerySubmission: function showGallerySubmission() {
				Ember.$('#button-show-upload').hide();
				Ember.$('.challenge-submit').show();
			},
			nextPage: function nextPage() {
				var page = this.get('page');
				this.set('page', page + 1);
			},
			prevPage: function prevPage() {
				var page = this.get('page');
				this.set('page', page - 1);
			}
		}
	});
});