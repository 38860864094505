define('frontend/services/-infinite-scroller', ['exports', 'ember-simple-infinite-scroller/services/-infinite-scroller'], function (exports, _infiniteScroller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _infiniteScroller.default;
    }
  });
});