define('frontend/controllers/application', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    rootUrl: _environment.default.rootURL,
    session: Ember.inject.service("session"),
    excludedClasses: ['header-drawer', 'header-nav-hamburger'],
    isOpen: false,
    isSearching: false,
    isHeaderCollapsed: false,
    isDrawerOpen: false,
    isMobileDisplay: false,
    startScroll: 0,
    initials: Ember.computed('session.data.authenticated.userFirstName', 'session.data.authenticated.userLastName', function () {
      var initials = '';
      var firstName = this.get('session.data.authenticated.userFirstName');
      var lastName = this.get('session.data.authenticated.userLastName');

      if (firstName) {
        initials = '' + initials + firstName.charAt(0);
      }
      if (lastName) {
        initials = '' + initials + lastName.charAt(0);
      }

      return initials;
    }),
    whichDisplay: function whichDisplay() {
      var _this = this;

      var isMobileDisplay = window.innerWidth < 768 ? true : false;
      setTimeout(function () {
        return _this.set("isMobileDisplay", isMobileDisplay);
      }, 500);
    },
    collapseHeader: function collapseHeader(event) {
      if (!this.get("isMobileDisplay")) {
        // Save current scroll position
        var currentScroll = window.scrollY;
        var friction = 20;
        console.log('Going to change header', currentScroll, friction, event.deltaY);

        // Detect if scrolling down
        if (event.deltaY > 0) {
          console.log('set isHeaderCollapsed true');
          this.set("isHeaderCollapsed", true);
        } else {
          // Detect if scrolling up
          console.log('set isHeaderCollapsed false');
          this.set("isHeaderCollapsed", false);
        }
        // assign start scroll to current scroll
        this.set("startScroll", currentScroll);
      }
    },
    init: function init() {
      console.log("Hello there");

      var whichDisplay = this.get("whichDisplay").bind(this);
      whichDisplay();
      window.addEventListener("resize", whichDisplay);
      if (!this.get("isMobileDisplay")) {
        console.log('init: register scroll hansler');
        window.addEventListener("mousewheel", this.get("collapseHeader").bind(this));
      }
      // Ember.$(document).on('click', function (e) {
      // 	if(!$(e.target).is('.header-drawer') || !$(e.target).is('.header-login')) {
      // 		// this.set("isDrawerOpen", false);
      // 	}
      // }.bind(this));
    },

    actions: {
      sendGAEvent: function sendGAEvent(eventCategory, eventLabel) {
        window.ga("send", "event", eventCategory, "Click", eventLabel);
      },
      submitSearch: function submitSearch() {
        var searchKeywords = this.get("searchKeywords");
        if (!searchKeywords || searchKeywords.length < 3) {
          return this.get("flashMessages").warning("Search terms must be at least 3 characters in length");
        }
        this.transitionToRoute("search", { queryParams: { q: searchKeywords } });
        this.set("searchKeywords", "");
      },
      toggleSearch: function toggleSearch() {
        this.toggleProperty("isSearching");
        this.set("searchKeywords", "");
        if (this.get("isSearching")) {
          // Focus search input field
          setTimeout(function () {
            return Ember.$('.search-field input[type="search"]').focus();
          }, 200);
        }
      },
      toggleDrawer: function toggleDrawer() {
        console.log(this);
        console.log('before', this.get("isDrawerOpen"));
        this.toggleProperty("isDrawerOpen");
        console.log('after', this.get("isDrawerOpen"));
      },
      hideDrawer: function hideDrawer() {
        var isMobileDisplay = this.get('isMobileDisplay');
        console.log('hideDrawer isMobileDisplay', isMobileDisplay);
        if (!isMobileDisplay) {
          console.log('hiding', isMobileDisplay);
          this.set("isDrawerOpen", false);
        }
      },
      toggleDropDown: function toggleDropDown() {
        this.toggleProperty("isOpen");
      },
      toggleNav: function toggleNav() {
        $(".header-collapsable-nav").toggleClass("active");
        $(".header-nav-toggle").toggleClass("active");
      },
      logout: function logout() {
        this.get("session").invalidate();
      },
      subscribe: function subscribe() {
        var _this2 = this;

        var _getProperties = this.getProperties("subscribeEmail"),
            subscribeEmail = _getProperties.subscribeEmail;

        if (!subscribeEmail) {
          $("#subscribe_error").text("Email is Missing");
          $("#subscribe_error").show();
          $("#subscribe_error").fadeOut(5000);
        } else {
          Ember.$.ajax({
            method: "POST",
            url: _environment.default.serverPath + "subscribe",
            data: {
              subscribeEmail: subscribeEmail
            }
          }).then(function (data) {
            if (data.success == false) {
              $("#subscribe_error").text(data.errors);
              $("#subscribe_error").show();
              $("#subscribe_error").fadeOut(5000);
            } else {
              $("#subscribe_success").text("Subscribe Successfully");
              $("#subscribe_success").show();
              $("#subscribe_success").fadeOut(5000);
            }
            _this2.setProperties({
              subscribeEmail: ""
            });
          });
        }
      }
    }
  });
});